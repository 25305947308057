// ServiceWorker registration. This is called from index.js
// In production, we register a service worker to serve assets from local cache.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on the "N+1" visit to a page, since previously
// cached resources are updated in the background.

// To learn more about the benefits of this model, read https://goo.gl/KwvDNy.
// This link also includes instructions on opting out of this behavior.
console.log('SERVICE WORKER: serviceWorker.js (registration) parsed')
const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost addyress.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

export function register(config) {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    window.addEventListener('load', () => {
    //  const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
      const swUrl = `${process.env.PUBLIC_URL}/sw.js`; // Note that we sw.js as the file name. Facebook changed from this to service-worker.js. You can however not change the name on live service workers. We therefore copy service-worker.js to sw.js as part of our build script. The rest remains the same. The template is service-worker.js
      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config);

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {
          console.log(
            'This web app is being served cache-first by a service ' +
              'worker. To learn more, visit https://goo.gl/SC7cgQ'
          );
        });
      } else {
        // Is not local host. Just register service worker
        registerValidSW(swUrl, config);
      }
    });
  }
}

// this function is called in the service worker registration promise, providing the ServiceWorkerRegistration instance
const registerPwaOpeningHandler = (registration) => {
  debugger
  let hidden;
  let visibilityChange;
  if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
      hidden = 'hidden';
      visibilityChange = 'visibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden';
      visibilityChange = 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
  }

  window.document.addEventListener(visibilityChange, () => {
      if (!document[hidden]) {
          console.log('SERVICE WORKER: App visibility Change, force check for update')
          // manually force detection of a potential update when the pwa is opened
          registration.update();
      }else{
        console.log('SERVICE WORKER: App visibility Change, document hidden -> no forced update check')
      }
  });
  return registration;
};


function registerValidSW(swUrl, config) {
  navigator.serviceWorker
    .register(swUrl)
    .then(registration => {
      registerPwaOpeningHandler(registration) // force detection of potential update
      console.log('SERVICE WORKER: register: ', { version: process?.env?.REACT_APP_VERSION, swUrl, config, registration})
      // New handling added to check if App is in waiting state already when loading.
      // This is to deal with scenarios where iOS may end up waiting as the state can be kept for a long time and potentally load new version before the event listeners are registered (https://stackoverflow.com/questions/55581719/reactjs-pwa-not-updating-on-ios)
      // if (registration.waiting) {
      //   // a new version is already waiting to take control
      //   // this.newWorker = reg.waiting;
      //   console.log('SERVICE WORKER: A new App version was loaded and waiting to update', )
      //   registration.waiting.postMessage({type: 'SKIP_WAITING'})
      //   // this.skipWaiting()
      //   // TODO: We may want to inform the user at this point that a new version is loaded
      //   /*
      //     code omitted: displays a snackbar to the user to manually trigger
      //     activation of the new SW. This will be done by calling skipWaiting()
      //     then reloading the page
      //   */
      // }
      registration.onupdatefound = () => {
        console.log('SERVICE WORKER: onUpdateFound: ', { registration})
        const installingWorker = registration.installing;
        if(installingWorker){
          installingWorker.onstatechange = () => {
            console.log('SERVICE WORKER: onUpdateFound - installing worker state change: ', { registration, state:installingWorker.state, installingWorker})
            if (installingWorker.state === 'installed') {
              if (navigator.serviceWorker.controller) {
                // At this point, the old content will have been purged and
                // the fresh content will have been added to the cache.
                // It's the perfect time to display a "New content is
                // available; please refresh." message in your web app.
                console.log('SERVICE WORKER: New content is available; please refresh.');

                // navigator.serviceWorker.skipWaiting().then(function() {
                //   alert('we skipped the waiting')
                // })

                // Execute callback
                if (config.onUpdate) {
                  config.onUpdate(registration);
                }
              } else {
                // At this point, everything has been precached.
                // It's the perfect time to display a
                // "Content is cached for offline use." message.
                console.log('SERVICE WORKER: Content is cached for offline use.');

                // Execute callback
                if (config.onSuccess) {
                  config.onSuccess(registration);
                }
              }
            }
          };
        }
      }
    })
    .catch(error => {
      console.error('Error during service worker registration:', error);
    });
}

function checkValidServiceWorker(swUrl, config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl)
    .then(response => {
      // Ensure service worker exists, and that we really are getting a JS file.
      if (
        response.status === 404 ||
        response.headers.get('content-type').indexOf('javascript') === -1
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log(
        'No internet connection found. App is running in offline mode.'
      );
    });
}

export function unregister(callback) {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then(registration => {
        registration.unregister().then( () => {
          if(callback) callback()
        });
      }).catch((error) => {
        console.error('SERVICE WORKER: unable to unregister - ' + error.message);
      });
  }
}