import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// import './polyfills.js';
import React from 'react';
import ReactDOM from 'react-dom';
import intl from 'react-intl-universal'

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import registerServiceWorker from './registerServiceWorker';
document.getElementById('root').innerHTML=`<div style="position: fixed; width:100%; max-width:1280px; height:100%; display: flex; align-items: center; justify-content: center;"><div>{intl.get('index.loadingApp').d('Loading App...')}</div></div>`
const startApp = () => {
    ReactDOM.render(<App />, document.getElementById('root'));

    // Reload page if service worker controller is changed. We ensure no infinite loop by setting the refreshing
    // let refreshing;
    // The event listener that is fired when the service worker updates
    // Here we reload the page
    if(!window.cordova){
        if('serviceWorker' in navigator) {
            navigator.serviceWorker.addEventListener('controllerchange', function () {
              // if (refreshing) return;
              localStorage.setItem('sw-state', 'READY');
              console.log('SERVICE WORKER: automatic reload of page after shifting to new version of App. This is necessary to avoid it being stuck on Apple if added to home screen')
              // refreshing = true;
              window.location.reload()
              // serviceWorker.unregister( () => window.location.reload())
            });
        }
        serviceWorker.register({
            onUpdate: (registration) => {
                localStorage.setItem('sw-state', 'NEW');
                console.log('SERVICE WORKER: New App Version Availalble', {registration})
                if(registration){
                    if(registration.waiting){
                        window.alert(intl.get('index.newAppVersion').d('New Version of the App. We will try and refresh App for you'))
                        registration.waiting.postMessage({type: 'SKIP_WAITING'})
                        // navigator.serviceWorker.controller.postMessage("skipWaiting");
                    }
                }
            },
            onSuccess: () => {
                localStorage.setItem('sw-state', 'READY');
                console.log('SERVICE WORKER: App downloaded and ready for use offline')
            }
        })
    }else{
        serviceWorker.unregister();
    }
}
// Wait for Cordova to initialize if run as Native App:
if(window.cordova) {
    document.addEventListener('deviceready', startApp, false);
} else {
    startApp();
}
